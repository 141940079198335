import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {OperationConfigType} from "../../../../server/src/db/classes/operation-config.type";

@Injectable({
  providedIn: "root"
})
export class SlackIntegrationService {
  constructor(private connectionService: ConnectionService) {}

  async getSlackOAuthUrl(id: string, type: OperationConfigType): Promise<{authUrl: string}> {
    return await this.connectionService.post("/operation-config/integration/slack/getOAuthUrl", {
      id,
      type
    });
  }

  async getSlackChannels(token: string): Promise<{id: string; name: string}[]> {
    return await this.connectionService.post("/operation-config/integration/slack/getSlackChannels", {
      token
    });
  }

  async manageSlackChannelId(id: string, channelId: string, token: string): Promise<boolean> {
    return await this.connectionService.post("/operation-config/integration/slack/manageSlackChannelId", {
      id,
      channelId,
      token
    });
  }
}
