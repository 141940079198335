<div class="slack-wrapper">
  <ng-container *ngIf="integration?.accessToken">
    <div class="slack-wrapper__header">
      <h3>Slack Integration</h3>
      <mat-slide-toggle
        color="primary"
        (change)="toggleEnable($event.checked)"
        [ngModel]="integration.enabled"
        [disabled]="!integration?.channelId">
        <strong>Enabled</strong>
      </mat-slide-toggle>
    </div>

    <p>Team ID: {{ integration.teamId }}</p>

    <mat-form-field>
      <mat-label>Select Channel</mat-label>
      <mat-select
        [(ngModel)]="integration.channelId"
        (selectionChange)="selectChannel($event.value)"
        [disabled]="isLoading">
        <mat-option *ngFor="let channel of channels" [value]="channel.id">
          {{ channel.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-button color="primary" (click)="loadChannels()" [disabled]="isLoading">
      {{ channels.length ? "Reload" : "Load" }} Channels
    </button>

    <ng-container *ngIf="!isConfirmDelete; else confirmDeleteTemplate">
      <button mat-button color="warn" (click)="confirmDelete()">Disconnect Slack</button>
    </ng-container>

    <ng-template #confirmDeleteTemplate>
      <p>Are you sure you want to disconnect Slack?</p>
      <button mat-button color="primary" (click)="disconnectSlack()">Yes</button>
      <button mat-button (click)="cancelDelete()">No</button>
    </ng-template>
  </ng-container>
</div>
