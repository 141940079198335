import {Component, Inject, Optional} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotificationIntegration} from "../../../../../server/src/db/classes/notification-integration.type";
import {IntegrationService} from "src/app/Services/integration.service";

@Component({
  selector: "app-display-error-dialog",
  templateUrl: "./display-error-dialog.component.html",
  styleUrls: ["./display-error-dialog.component.scss"]
})
export class DisplayErrorDialogComponent {
  integration: NotificationIntegration;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      integration: NotificationIntegration;
      operationConfigId: string;
    },
    public dialogRef: MatDialogRef<DisplayErrorDialogComponent>,
    private integrationService: IntegrationService
  ) {
    this.integration = data.integration;
  }

  async resetErrors() {
    if (this.integration.errors) {
      await this.integrationService.removeErrors(this.data.operationConfigId, this.data.integration.type);
      delete this.integration.errors;
      this.closeDialog();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
