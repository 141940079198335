import { Injectable } from '@angular/core';
import { ConnectionService } from './connection.service';
import { OperationConfigType } from '../../../../server/src/db/classes/operation-config.type';
import {
  SmsIntegration,
  SmsIntegrationPhone,
} from '../../../../server/src/db/classes/notification-integration.type';
import { PhoneVerification } from '../../../../server/src/db/classes/phone-verification';

@Injectable({
  providedIn: 'root',
})
export class SmsIntegrationService {
  constructor(private connectionService: ConnectionService) {}

  async sendVerifyPhoneSms(
    number: string,
    operationConfigId: string
  ): Promise<PhoneVerification> {
    return await this.connectionService.post(
      '/operation-config/integration/sms/sendVerifyPhoneSms',
      {
        number,
        operationConfigId,
      }
    );
  }

  async addVerifiedPhone(
    id: string,
    phone: SmsIntegrationPhone,
    code: number,
    type: OperationConfigType
  ): Promise<SmsIntegration> {
    return await this.connectionService.post(
      '/operation-config/integration/sms/addVerifiedPhone',
      {
        id,
        phone,
        code,
        type,
      }
    );
  }

  async removePhoneNumber(id: string, phone: SmsIntegrationPhone): Promise<boolean> {
    return await this.connectionService.post(
      '/operation-config/integration/sms/removePhoneNumber',
      {
        id,
        phone,
      }
    );
  }
}

