<h2 mat-dialog-title>
  {{ integration.type || "Error Details" }} Error<span *ngIf="integration.errors.length > 1">s</span>
</h2>

<div mat-dialog-content style="white-space: pre-wrap; padding: 24px !important">
  <ng-container *ngIf="integration.errors && integration.errors.length > 0; else noErrors">
    <div class="error-message" *ngFor="let error of integration.errors; let last = last">
      <div class="font-weight: bold">
        <mat-icon class="redColor" style="vertical-align: bottom">warning</mat-icon>
        {{ error.errorCreatedAt | timeAgo }}:
      </div>
      <div>{{ error.errorMessage || "Unknown Error" }}</div>
      <ng-container *ngIf="!last">
        <br />
        <br />
      </ng-container>
    </div>
  </ng-container>

  <ng-template #noErrors>
    <div>No errors to display.</div>
  </ng-template>
</div>

<div mat-dialog-actions>
  <button mat-button color="warn" (click)="resetErrors()" *ngIf="integration.errors && integration.errors.length > 0">
    Clear Errors
  </button>

  <button mat-button cdkFocusInitial mat-raised-button color="primary" (click)="closeDialog()">Ok</button>
</div>
