import {UntilDestroy} from "@ngneat/until-destroy";
import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {LogoService} from "../../Services/logo-service";

@UntilDestroy()
@Component({
  selector: "app-accepted",
  templateUrl: "./accepted.component.html",
  styleUrls: ["./accepted.component.scss"]
})
export class AcceptedComponent {
  public isSuccess = false;
  public successText = "Source-Token Added Successfully";

  constructor(
    private router: Router,
    public logoService: LogoService
  ) {
    if (this.router.url === "/accepted_finished") {
      this.isSuccess = true;
    }

    if (this.router.url === "/accepted_slack_finished") {
      this.isSuccess = true;
      this.successText =
        "Slack is now successfully connected. Please go to the integration settings and select the chat option to start receiving messages.";
    }
  }
}
