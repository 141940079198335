import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "timeAgo",
  standalone: true,
  pure: true
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) return value;
    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
    if (seconds < 30) return "Just now";

    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1
    };

    for (const unit in intervals) {
      const count = Math.floor(seconds / intervals[unit]);
      if (count > 0) return `${count} ${unit}${count > 1 ? "s" : ""} ago`;
    }
  }
}
